import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../contexts/AuthContext";
import { GlobalContext } from "../contexts/GlobalContext";

import {
  faListOl,
  faRightToBracket,
  faGear,
  faCalendarDays,
  faTrophy,
  faAward,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

function MenuBar() {
  const { user } = useContext(AuthContext);
  const { lineupSet } = useContext(GlobalContext);

  const [lineupIconClass, setLineupIconClass] = useState("");

  useEffect(() => {
    if (user) {
      if (lineupSet) {
        setLineupIconClass("lineup-set");
      } else {
        setLineupIconClass("lineup-missing");
      }
    } else {
      setLineupIconClass("");
    }
  }, [user, lineupSet]);

  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/" className="nav-link">
            <img
              className="nav-logo"
              src={require("../images/insignificant_league_icon.jpg")}
              alt="The Insignificant League Logo"
            />
            <span className="link-text">The Insignificant League</span>
          </Link>
        </li>
        <li className="nav-item" id="table-nav-item">
          <Link to="/" className="nav-link">
            <FontAwesomeIcon icon={faListOl} size="xl" />
            <span className="link-text">Table</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/lineups" className="nav-link">
            <FontAwesomeIcon
              icon={faCalendarDays}
              size="xl"
              className={lineupIconClass}
            />
            <span className="link-text">Lineups</span>
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link to="/cup" className="nav-link">
            <FontAwesomeIcon icon={faTrophy} size="xl" />
            <span className="link-text">Cup</span>
          </Link>
        </li> */}
        <li className="nav-item">
          <Link to="/players" className="nav-link">
            <FontAwesomeIcon icon={faPeopleGroup} size="xl" />
            <span className="link-text">Players</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/records" className="nav-link">
            <FontAwesomeIcon icon={faAward} size="xl" />
            <span className="link-text">Records</span>
          </Link>
        </li>
        {user ? (
          <li className="nav-item">
            <Link to="/settings" className="nav-link">
              <FontAwesomeIcon icon={faGear} size="xl" />
              <span className="link-text">Settings</span>
            </Link>
          </li>
        ) : (
          <li className="nav-item">
            <Link to="/login" className="nav-link">
              <FontAwesomeIcon icon={faRightToBracket} size="xl" />
              <span className="link-text">Login</span>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default MenuBar;
