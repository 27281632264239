import gql from "graphql-tag";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { AuthContext } from "../contexts/AuthContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

function LoginForm(props) {
  const { seasonId } = props;
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const LOGIN_USER = gql`
    mutation login($email: String!, $password: String!, $seasonId: String!) {
      login(email: $email, password: $password, seasonId: $seasonId) {
        _id
        name
        token
        teamId
      }
    }
  `;

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(_, { data: { login: userData } }) {
      context.login(userData);
      navigate("/");
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.errors);
    },
    variables: values,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      seasonId: seasonId,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="form-item">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            onChange={handleChange}
            name="email"
            placeholder="Enter your email address"
          />
        </div>
        <div className="form-item">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            onChange={handleChange}
            name="password"
            placeholder="Enter your password"
          />
        </div>
        <div className="form-item">
          <button type="submit">
            <FontAwesomeIcon icon={faRightToBracket} size="lg" />
            Login
          </button>
        </div>
      </form>

      {Object.keys(errors).length > 0 && (
        <div className="login-errors">
          <ul>
            {Object.values(errors).map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
