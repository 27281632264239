export const useLineupSelection = () => {
  const canSelectPlayer = (
    playerPositions,
    selectedPlayers,
    playerPosition
  ) => {
    // lineup is full so nopes
    if (selectedPlayers.length === 11) {
      return false;
    }

    const currentFormation = getCurrentFormation(
      playerPositions,
      selectedPlayers
    );

    // if selecting this position will prevent us hitting min on all other positions then nopes
    const spacesRemaining = 11 - selectedPlayers.length - 1;

    const toHitMinimum = {
      1: minPlayerPerPosition[1] - currentFormation[1],
      2: minPlayerPerPosition[2] - currentFormation[2],
      3: minPlayerPerPosition[3] - currentFormation[3],
      4: minPlayerPerPosition[4] - currentFormation[4],
    };

    toHitMinimum[playerPosition]--;
    let selectionsToHitMin = 0;
    if (toHitMinimum[1] > 0) {
      selectionsToHitMin += toHitMinimum[1];
    }

    if (toHitMinimum[2] > 0) {
      selectionsToHitMin += toHitMinimum[2];
    }

    if (toHitMinimum[3] > 0) {
      selectionsToHitMin += toHitMinimum[3];
    }

    if (toHitMinimum[4] > 0) {
      selectionsToHitMin += toHitMinimum[4];
    }

    if (
      toHitMinimum[playerPosition] < 1 &&
      spacesRemaining < selectionsToHitMin
    ) {
      return false;
    }

    // already full allocation of players in this position, nopes
    if (
      currentFormation[playerPosition] + 1 >
      maxPlayerPerPosition[playerPosition]
    ) {
      return false;
    }

    return true;
  };

  const getCurrentFormationString = (playerPositions, selectedPlayers) => {
    const currentFormation = getCurrentFormation(
      playerPositions,
      selectedPlayers
    );

    return `${currentFormation[2]}-${currentFormation[3]}-${currentFormation[4]}`;
  };

  const getPlayerPosition = (playerPositions, playerId) => {
    return playerPositions.find((pp) => pp._id === playerId);
  };

  const getCurrentFormation = (playerPositions, selectedPlayers) => {
    if (!selectedPlayers) {
      return "";
    }

    const currentFormation = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    };

    selectedPlayers.forEach((selectedPlayerId) => {
      const playerPosition = getPlayerPosition(
        playerPositions,
        selectedPlayerId
      );

      if (playerPosition) {
        currentFormation[playerPosition.Position]++;
      }
    });

    return currentFormation;
  };

  const minPlayerPerPosition = {
    1: 1,
    2: 3,
    3: 2,
    4: 1,
  };

  const maxPlayerPerPosition = {
    1: 1,
    2: 5,
    3: 5,
    4: 3,
  };

  return {
    canSelectPlayer,
    getCurrentFormationString,
  };
};
