import React from "react";
import PageHeader from "../components/PageHeader";
import LeagueAndCup from "../components/records/LeagueAndCup";

function Records() {
  return (
    <div>
      <PageHeader pageTitle="Records" />
      <LeagueAndCup />
    </div>
  );
}

export default Records;
