import React from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

function TeamLink(props) {
  const { teamName, teamId } = props;
  const navigate = useNavigate();

  const handleTeamLinkClick = () => {
    navigate("/team/" + teamId);
  };

  return (
    <div className="team-link" onClick={handleTeamLinkClick}>
      {teamName}
      <FontAwesomeIcon icon={faPeopleGroup} size="lg" />
    </div>
  );
}

export default TeamLink;
