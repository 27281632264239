import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import PlayerPosition from "./PlayerPosition";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotate,
  faCalendarDays,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

function WaiversRecord(props) {
  const { seasonId, gameweek } = props;
  const navigate = useNavigate();

  const [processedWaiversList, setProcessedWaiversList] = useState([]);
  const [rejectedWaiversList, setRejectedWaiversList] = useState([]);

  const FETCH_WAIVERS_RECORD = gql`
    query ($seasonId: String!, $gameweek: Int!) {
      getWaiversRecord(seasonId: $seasonId, gameweek: $gameweek) {
        _id
        SeasonId
        Gameweek
        TeamWaivers {
          TeamId
          TeamName
          PositionInTeamsWaiver
          PlayerToDrop {
            Position
            WebName
          }
          PlayerToPickUp {
            Position
            WebName
          }
          Status
          RejectionReason
          OrderProcessed
          AlreadyTakenByInfo {
            AlreadyTakenByWaiverOrder
            AlreadyTakenByTeamName
            AlreadyTakenByTeamId
          }
        }
      }
    }
  `;

  const [
    loadWaiversRecord,
    { loading, data: { getWaiversRecord: waiversRecord } = {} },
  ] = useLazyQuery(FETCH_WAIVERS_RECORD, {
    variables: { seasonId, gameweek },
  });

  useEffect(() => {
    if (seasonId && (gameweek || gameweek === 0)) {
      loadWaiversRecord();
    }
  }, [seasonId, gameweek]);

  useEffect(() => {
    if (waiversRecord) {
      setProcessedWaiversList([
        ...waiversRecord.TeamWaivers.filter((tw) => tw.OrderProcessed > 0),
      ]);
      setRejectedWaiversList([
        ...waiversRecord.TeamWaivers.filter((tw) => tw.OrderProcessed === 0),
      ]);
    }
  }, [waiversRecord]);

  const waiverClass = (status) => {
    if (status === "Processed") {
      return "waiver-processed";
    }

    return "waiver-rejected";
  };

  const waiverRejectionReason = (status, reason) => {
    if (status === "Processed") {
      return;
    }

    if (reason === "InvalidatesLineups") {
      return faCalendarDays;
    }

    if (reason === "TooManyFromTeam") {
      return faPeopleGroup;
    }

    return faRotate;
  };

  return (
    <table className="waiver-record">
      <thead>
        <tr>
          <th></th>
          <th>Team</th>
          <th>Out</th>
          <th></th>
          <th>In</th>
          <th></th>
          <th>?</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {loading ? (
          <tr>
            <td>Loading table...</td>
          </tr>
        ) : (
          processedWaiversList &&
          processedWaiversList
            .sort((a, b) => a.OrderProcessed - b.OrderProcessed)
            .map((pw, index) => (
              <React.Fragment key={index}>
                <tr className={waiverClass(pw.Status)}>
                  <td>{pw.OrderProcessed}</td>
                  <td>{pw.TeamName}</td>
                  <td>
                    <PlayerPosition positionNumber={pw.PlayerToDrop.Position} />
                  </td>
                  <td>{pw.PlayerToDrop.WebName}</td>
                  <td>
                    <PlayerPosition
                      positionNumber={pw.PlayerToPickUp.Position}
                    />
                  </td>
                  <td>{pw.PlayerToPickUp.WebName}</td>
                  <td>{pw.PositionInTeamsWaiver}</td>
                  <td>
                    {pw.Status === "Rejected" ? (
                      <FontAwesomeIcon
                        icon={waiverRejectionReason(
                          pw.Status,
                          pw.RejectionReason
                        )}
                        size="lg"
                      />
                    ) : (
                      <span></span>
                    )}
                  </td>
                </tr>
                {rejectedWaiversList
                  .filter(
                    (rw) =>
                      rw.AlreadyTakenByInfo.AlreadyTakenByWaiverOrder ===
                      pw.OrderProcessed
                  )
                  .sort(
                    (a, b) =>
                      a.PositionInTeamsWaiver - b.PositionInTeamsWaiver ||
                      a.TeamName.localeCompare(b.TeamName)
                  )
                  .map((rw, index) => (
                    <tr key={`${pw.OrderProcessed}_${index}`}>
                      <td></td>
                      <td>{rw.TeamName}</td>
                      <td>
                        <PlayerPosition
                          positionNumber={rw.PlayerToDrop.Position}
                        />
                      </td>
                      <td>{rw.PlayerToDrop.WebName}</td>
                      <td>
                        <PlayerPosition
                          positionNumber={rw.PlayerToPickUp.Position}
                        />
                      </td>
                      <td>{rw.PlayerToPickUp.WebName}</td>
                      <td>{rw.PositionInTeamsWaiver}</td>
                      <td>
                        <FontAwesomeIcon
                          icon={waiverRejectionReason(
                            rw.Status,
                            rw.RejectionReason
                          )}
                          size="lg"
                        />
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))
        )}
      </tbody>
    </table>
  );
}

export default WaiversRecord;
