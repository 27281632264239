import React from "react";
import Logout from "../components/Logout";
import PageHeader from "../components/PageHeader";

function Settings() {
  return (
    <div>
      <PageHeader pageTitle="Settings" />
      <Logout />
    </div>
  );
}

export default Settings;
