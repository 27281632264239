import React, { useContext } from "react";

import { GlobalContext } from "../contexts/GlobalContext";

import PageHeader from "../components/PageHeader";
import CupTables from "../components/cup/CupTables";
import CupFixtures from "../components/cup/CupFixtures";
import CupKnockoutFixtures from "../components/cup/CupKnockoutFixtures";

function Cup() {
  const { globalSeasonId, globalGameweek, globalActiveCup } =
    useContext(GlobalContext);

  return (
    <div>
      <PageHeader pageTitle="Cup Fixtures and Tables" />
      <CupKnockoutFixtures
        seasonId={globalSeasonId}
        activeCup={globalActiveCup}
      />
      <CupFixtures seasonId={globalSeasonId} activeCup={globalActiveCup} />
      <CupTables
        seasonId={globalSeasonId}
        gameweek={globalGameweek}
        activeCup={globalActiveCup}
      />
    </div>
  );
}

export default Cup;
