import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import ManagerRecords from "../components/records/ManagerRecords";

function Manager(props) {
  const { managerId } = useParams();
  const [managerName, setManagerName] = useState("");

  const getManagerNameFromRecords = (nameFromRecords) => {
    setManagerName(nameFromRecords);
  };

  return (
    <div>
      <PageHeader pageTitle={managerName} />
      <ManagerRecords
        managerId={managerId}
        managerNameCallback={getManagerNameFromRecords}
      />
    </div>
  );
}

export default Manager;
