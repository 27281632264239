import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy, faPoop } from "@fortawesome/free-solid-svg-icons";

function Trophies(props) {
  const { winnerCount, runnerUpCount, thirdCount, loserCount } = props;

  const winnerTrophies = [];
  const runnerUpTrophies = [];
  const thirdTrophies = [];
  const loserTrophies = [];

  for (let index = 0; index < winnerCount; index++) {
    winnerTrophies.push(
      <FontAwesomeIcon
        icon={faTrophy}
        key={"winnerTrophy_" + index}
        className="winner-trophy"
      />
    );
  }

  for (let index = 0; index < runnerUpCount; index++) {
    runnerUpTrophies.push(
      <FontAwesomeIcon
        icon={faTrophy}
        key={"runnerUpTrophy_" + index}
        className="runnerup-trophy"
      />
    );
  }

  for (let index = 0; index < thirdCount; index++) {
    thirdTrophies.push(
      <FontAwesomeIcon
        icon={faTrophy}
        key={"thirdTrophy_" + index}
        className="third-trophy"
      />
    );
  }

  for (let index = 0; index < loserCount; index++) {
    loserTrophies.push(
      <FontAwesomeIcon
        icon={faPoop}
        key={"loserTrophy_" + index}
        className="loser-trophy"
      />
    );
  }

  return (
    <React.Fragment>
      {winnerTrophies}
      {runnerUpTrophies}
      {thirdTrophies}
      {loserTrophies}
    </React.Fragment>
  );
}

export default Trophies;
