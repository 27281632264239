import React from "react";
import TablePage from "./TablePage";
import Deadlines from "../components/Deadlines";

function Home() {
  return (
    <React.Fragment>
      <div>
        <TablePage />
      </div>

      <div>
        <Deadlines />
      </div>
    </React.Fragment>
  );
}

export default Home;
