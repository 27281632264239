import React, { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { useDates } from "../hooks/useDates";

function Deadlines() {
  const { globalLineupDeadline, globalWaiversDeadline } =
    useContext(GlobalContext);
  const { parseTimestamp } = useDates();

  return (
    <React.Fragment>
      <p className="deadline-date">
        {globalWaiversDeadline
          ? `Waivers deadline @ ${parseTimestamp(globalWaiversDeadline)}`
          : "Loading waivers deadline..."}
      </p>

      <p className="deadline-date">
        {globalLineupDeadline
          ? `Lineup deadline @ ${parseTimestamp(globalLineupDeadline)}`
          : "Loading lineup deadline..."}
      </p>
    </React.Fragment>
  );
}

export default Deadlines;
