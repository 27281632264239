import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import SubHeader from "../SubHeader";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";

const FETCH_MANAGER_RECORDS = gql`
  query ($managerId: String!) {
    getManagerRecords(managerId: $managerId) {
      LeagueResults {
        ManagerName
        SeasonStandings {
          SeasonId
          SeasonName
          Position
          TotalPoints
        }
      }
      CupResults {
        SeasonStandings {
          SeasonId
          Position
        }
      }
    }
  }
`;

function ManagerRecords(props) {
  const { managerId, managerNameCallback } = props;
  const [seasonalData, setSeasonalData] = useState([]);
  const navigate = useNavigate();

  const { loading, data: { getManagerRecords: returnedManagerRecords } = {} } =
    useQuery(FETCH_MANAGER_RECORDS, {
      variables: { managerId },
    });

  useEffect(() => {
    if (returnedManagerRecords) {
      managerNameCallback(returnedManagerRecords.LeagueResults[0].ManagerName);
      returnedManagerRecords.LeagueResults[0].SeasonStandings.forEach((ss) => {
        let season = {
          SeasonId: ss.SeasonId,
          SeasonName: ss.SeasonName,
          Position: ss.Position,
          Points: ss.TotalPoints,
        };

        let cupPositions =
          returnedManagerRecords.CupResults[0].SeasonStandings.filter(
            (cup) => cup.SeasonId === ss.SeasonId
          ).map((cup) => {
            return cup.Position;
          });

        season.CupPositions = cupPositions;

        seasonalData.push(season);
      });

      setSeasonalData(seasonalData);
    }
  }, [returnedManagerRecords]);

  if (loading) {
    return <div>Loading manager records...</div>;
  }

  const handleSeasonClick = (seasonId) => {
    let gameweek = 38;
    if (seasonId === "3Pu8Cj8yLQxguchZP") {
      gameweek = 47;
    }

    navigate(`/records/${seasonId}/${gameweek}`);
  };

  const mapLeaguePosition = (leaguePosition, seasonIndex) => {
    if (leaguePosition === 1) {
      return (
        <FontAwesomeIcon
          icon={faTrophy}
          key={`winnerTrophy_"${seasonIndex}`}
          className="winner-trophy"
        />
      );
    }

    if (leaguePosition === 2) {
      return (
        <FontAwesomeIcon
          icon={faTrophy}
          key={`runnerUpTrophy_"${seasonIndex}`}
          className="runnerup-trophy"
        />
      );
    }

    if (leaguePosition === 3) {
      return (
        <FontAwesomeIcon
          icon={faTrophy}
          key={`thirdTrophy_"${seasonIndex}`}
          className="third-trophy"
        />
      );
    }

    return leaguePosition;
  };

  const mapCupPosition = (cupPosition, seasonIndex, cupIndex) => {
    if (cupPosition === 1) {
      return (
        <FontAwesomeIcon
          icon={faTrophy}
          key={`winnerTrophy_"${seasonIndex}_${cupIndex}`}
          className="winner-trophy"
        />
      );
    }

    if (cupPosition === 2) {
      return (
        <FontAwesomeIcon
          icon={faTrophy}
          key={`runnerUpTrophy_"${seasonIndex}_${cupIndex}`}
          className="runnerup-trophy"
        />
      );
    }

    if (cupPosition === 3) {
      return " S ";
    }

    if (cupPosition === 4) {
      return " WC ";
    }

    return " G ";
  };

  return (
    <div>
      {returnedManagerRecords && (
        <React.Fragment>
          <SubHeader subTitle="Manager Records" />
          <table>
            <thead>
              <tr>
                <th>Season</th>
                <th>Pos</th>
                <th>Pts</th>
                <th>Cups</th>
              </tr>
            </thead>
            <tbody>
              {seasonalData &&
                seasonalData.map((season, seasonIndex) => {
                  return (
                    <tr key={season.SeasonId}>
                      <td onClick={() => handleSeasonClick(season.SeasonId)}>
                        {season.SeasonName}
                      </td>
                      <td>{mapLeaguePosition(season.Position)}</td>
                      <td>{season.Points}</td>
                      <td>
                        {season.CupPositions.map((cp, cupIndex) =>
                          mapCupPosition(cp, seasonIndex, cupIndex)
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </React.Fragment>
      )}
    </div>
  );
}

export default ManagerRecords;
