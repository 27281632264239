import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

import PlayerPosition from "./PlayerPosition";
import TeamLink from "./TeamLink";

function Gameweek(props) {
  const { seasonId, gameweek, teamId } = props;

  const FETCH_GAMEWEEK = gql`
    query ($seasonId: String!, $gameweek: Int!, $teamId: String!) {
      getGameweek(seasonId: $seasonId, gameweek: $gameweek, teamId: $teamId) {
        TeamName
        Formation
        TotalPoints
        Players {
          Selected
          PointsScoredForTeam
          Player {
            WebName
            RecentPoints
            Fixtures
            Position
          }
        }
      }
    }
  `;

  const [
    loadGameweekData,
    { loading, data: { getGameweek: returnedGameweek } = {} },
  ] = useLazyQuery(FETCH_GAMEWEEK, {
    variables: { seasonId, gameweek, teamId },
  });

  useEffect(() => {
    if (seasonId && gameweek) {
      loadGameweekData();
    }
  }, [seasonId, gameweek]);

  return (
    <React.Fragment>
      {returnedGameweek && (
        <TeamLink teamName={returnedGameweek.TeamName} teamId={teamId} />
      )}
      <table className="table-gameweek">
        <thead>
          <tr>
            <th>Pos</th>
            <th>Name</th>
            <th>Fixtures</th>
            <th>Points</th>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <td>Loading players...</td>
            </tr>
          ) : (
            <React.Fragment>
              {returnedGameweek &&
                returnedGameweek.Players.slice()
                  .sort(
                    (a, b) =>
                      a.Player.Position - b.Player.Position ||
                      b.Player.RecentPoints - a.Player.RecentPoints
                  )
                  .map((p, index) => {
                    if (p.Selected) {
                      return (
                        <tr
                          key={index}
                          className={`player-selected player-selected-${p.Player.Position}`}
                        >
                          <td>
                            <PlayerPosition
                              positionNumber={p.Player.Position}
                            />
                          </td>
                          <td>{p.Player.WebName}</td>
                          <td>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: p.Player.Fixtures,
                              }}
                            />
                          </td>
                          <td>{p.PointsScoredForTeam}</td>
                        </tr>
                      );
                    }
                  })}
              {returnedGameweek && (
                <tr>
                  <td>{returnedGameweek.Formation}</td>
                  <td></td>
                  <td></td>
                  <td>{returnedGameweek.TotalPoints}</td>
                </tr>
              )}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default Gameweek;
