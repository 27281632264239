import React from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

function ManagerLink(props) {
  const { managerName, managerId } = props;
  const navigate = useNavigate();

  const handleManagerLinkClick = () => {
    navigate("/manager/" + managerId);
  };

  return (
    <div className="manager-link" onClick={handleManagerLinkClick}>
      {managerName}
      <FontAwesomeIcon icon={faUserTie} size="lg" />
    </div>
  );
}

export default ManagerLink;
