import React, { useContext } from "react";
import Gameweek from "../components/Gameweek";
import { useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import { GlobalContext } from "../contexts/GlobalContext";
import { useGlobalOrSelected } from "../hooks/useGlobalOrSelected";
import GameweekNav from "../components/GameweekNav";

function Result() {
  const { globalSeasonId, globalGameweek } = useContext(GlobalContext);
  const { teamId, gameweek } = useParams();
  const { getGameweek } = useGlobalOrSelected();

  const gameweekNumber = getGameweek(globalGameweek, gameweek);

  return (
    <div>
      <PageHeader pageTitle="Result" gameweek={gameweekNumber} />
      <Gameweek
        seasonId={globalSeasonId}
        gameweek={gameweekNumber}
        teamId={teamId}
      />
      <GameweekNav
        globalGameweek={globalGameweek}
        currentlySelectedGameweek={gameweekNumber}
        prefix={`result/${teamId}`}
      />
    </div>
  );
}

export default Result;
