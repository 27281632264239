import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import GlobalProvider from "./contexts/GlobalContext";
import { AuthProvider } from "./contexts/AuthContext";

import MenuBar from "./components/MenuBar";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Result from "./pages/Result";
import Lineups from "./pages/Lineups";
import Lineup from "./pages/Lineup";
import Cup from "./pages/Cup";
import TeamPage from "./pages/TeamPage";
import Records from "./pages/Records";
import Settings from "./pages/Settings";
import Manager from "./pages/Manager";
import TablePage from "./pages/TablePage";
import PlayersPage from "./pages/PlayersPage";
import WaiversPage from "./pages/WaiversPage";
import WaiversRecords from "./pages/WaiversRecordsPage";
import "./App.css";

function App() {
  return (
    <div className="app-container">
      <AuthProvider>
        <GlobalProvider>
          <Router>
            <MenuBar />
            <main>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/result/:teamId" element={<Result />} />
                <Route path="/result/:teamId/:gameweek" element={<Result />} />
                <Route path="/login" element={<Login />} />
                <Route path="/lineups" element={<Lineups />} />
                <Route path="/lineup/:teamId" element={<Lineup />} />
                <Route path="/cup" element={<Cup />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/team/:teamId" element={<TeamPage />} />
                <Route path="/records" element={<Records />} />
                <Route
                  path="/records/:seasonId/:gameweek"
                  element={<TablePage />}
                />
                <Route path="/manager/:managerId" element={<Manager />} />
                <Route path="/table/:gameweek" element={<TablePage />} />
                <Route path="/players" element={<PlayersPage />} />
                <Route path="/players/:playerId" element={<PlayersPage />} />
                <Route
                  path="/team/waivers/:teamId/:gameweek"
                  element={<WaiversPage />}
                />
                <Route path="/waivers/:gameweek" element={<WaiversRecords />} />
              </Routes>
            </main>
          </Router>
        </GlobalProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
