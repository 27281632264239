import React, { useEffect, useState } from "react";
import SubHeader from "./SubHeader";
import PlayerPosition from "./PlayerPosition";
import { useLineupSelection } from "../hooks/useLineupSelection";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faPlaneDeparture,
  faBan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function PlayerList(props) {
  const {
    players,
    selectedPlayers,
    current,
    setSelectedPlayers,
    setFormation,
    canEdit,
    singleSelect,
  } = props;
  const subTitle = (current ? "Current " : "Previous ") + "Players";
  const [activeNewsItem, setActiveNewsItem] = useState("");
  const { canSelectPlayer, getCurrentFormationString } = useLineupSelection();

  const playerPositions = players.map((p) => {
    return {
      _id: p.Player._id,
      Position: p.Player.Position,
    };
  });

  const handlePlayerNewsIconClick = (playerId) => {
    if (activeNewsItem === playerId + "_news") {
      setActiveNewsItem("");
      return;
    }

    setActiveNewsItem(playerId + "_news");
  };

  const handlePlayerNewsRowClick = () => {
    setActiveNewsItem("");
  };

  const playerSelectedClass = (playerId, position) => {
    if (
      singleSelect &&
      selectedPlayers &&
      selectedPlayers.map((p) => p._id).includes(playerId)
    ) {
      return `player-selected player-selected-${position}`;
    }

    if (selectedPlayers && selectedPlayers.includes(playerId)) {
      return `player-selected player-selected-${position}`;
    }

    return "";
  };

  const playerReasonForAbsence = (news) => {
    if (!news) {
      return "";
    }

    let newsLower = news.toLowerCase();

    if (newsLower.includes("loan") || newsLower.includes("transfer")) {
      return faPlaneDeparture;
    }

    if (newsLower.includes("suspended")) {
      return faBan;
    }

    return faTriangleExclamation;
  };

  const playerChanceOfPlayingClass = (chanceOfPlayingPercentage) => {
    if (chanceOfPlayingPercentage < 0) {
      return "";
    }

    return `player-news-chance-${chanceOfPlayingPercentage}-icon`;
  };

  const handlePlayerClick = (player) => {
    if (singleSelect) {
      if (canEdit && current && setSelectedPlayers) {
        if (selectedPlayers && selectedPlayers.includes(player)) {
          setSelectedPlayers(selectedPlayers.filter((p) => p !== player));
        } else {
          if (singleSelect && selectedPlayers && selectedPlayers.length === 1) {
            return;
          }

          setSelectedPlayers([...selectedPlayers, player]);
        }
      }
    } else {
      if (canEdit && current && setSelectedPlayers) {
        if (selectedPlayers && selectedPlayers.includes(player._id)) {
          setSelectedPlayers(
            selectedPlayers.filter((pid) => pid !== player._id)
          );
        } else {
          if (singleSelect && selectedPlayers && selectedPlayers.length === 1) {
            return;
          }

          if (
            canSelectPlayer(playerPositions, selectedPlayers, player.Position)
          ) {
            setSelectedPlayers([...selectedPlayers, player._id]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (!setFormation) {
      return;
    }

    const formationString = getCurrentFormationString(
      playerPositions,
      selectedPlayers
    );
    setFormation(formationString);
  }, [selectedPlayers]);

  return (
    <React.Fragment>
      <SubHeader subTitle={subTitle} />
      <table className="table-players">
        <thead>
          <tr>
            <th>Pos</th>
            <th>Name</th>
            <th>Fixtures</th>
            <th>RP</th>
            <th>TP</th>
            <th>PFT</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <React.Fragment>
            {players &&
              players
                .slice()
                .sort(
                  (a, b) =>
                    a.Player.Position - b.Player.Position ||
                    b.Player.TotalPoints - a.Player.TotalPoints ||
                    b.Player.RecentPoints - a.Player.RecentPoints
                )
                .map((p) => {
                  return (
                    <React.Fragment key={p.Player._id}>
                      <tr
                        className={playerSelectedClass(
                          p.Player._id,
                          p.Player.Position
                        )}
                        onClick={() => handlePlayerClick(p.Player)}
                      >
                        <td>
                          <PlayerPosition positionNumber={p.Player.Position} />
                        </td>
                        <td>{p.Player.WebName}</td>
                        <td>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: p.Player.Fixtures,
                            }}
                          />
                        </td>
                        <td>{p.Player.RecentPoints}</td>
                        <td>{p.Player.TotalPoints}</td>
                        <td>{p.TotalPointsScoredForTeam}</td>
                        <td className="player-news-cell">
                          {p.Player.News && (
                            <FontAwesomeIcon
                              icon={playerReasonForAbsence(p.Player.News)}
                              size="lg"
                              className={playerChanceOfPlayingClass(
                                p.Player.ChanceOfPlayingPercentage
                              )}
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePlayerNewsIconClick(p.Player._id);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      {p.Player.News !== "" && (
                        <tr hidden={activeNewsItem !== p.Player._id + "_news"}>
                          <td
                            colSpan="6"
                            onClick={(e) => {
                              e.stopPropagation();
                              handlePlayerNewsRowClick();
                            }}
                          >
                            {p.Player.News}
                          </td>
                          <td className="player-news-cell">
                            <FontAwesomeIcon
                              icon={faXmark}
                              size="lg"
                              className="player-news-warning"
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePlayerNewsRowClick();
                              }}
                            />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
            {players &&
              playerPositions &&
              selectedPlayers &&
              selectedPlayers.length > 0 &&
              !singleSelect && (
                <tr>
                  <td colSpan="4">
                    {getCurrentFormationString(
                      playerPositions,
                      selectedPlayers
                    )}
                  </td>
                </tr>
              )}
          </React.Fragment>
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default PlayerList;
