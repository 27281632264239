import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { environmentVars } from "./environment";
import { setContext } from "apollo-link-context";
import jwtDecode from "jwt-decode";

const httpLink = createHttpLink({
  uri: environmentVars.url.API,
});

const authLink = setContext(() => {
  const token = localStorage.getItem("TLFPL_JWTTOKEN");

  if (token) {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem("TLFPL_JWTTOKEN");
      window.location.reload();
    }
  }

  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
