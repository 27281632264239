import React, { useEffect, useState, useContext } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import PlayerList from "./PlayerList";
import PlayerPosition from "./PlayerPosition";
import { AuthContext } from "../contexts/AuthContext";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useDates } from "../hooks/useDates";
import PlayerSearch from "../components/PlayerSearch";
import { GlobalContext } from "../contexts/GlobalContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotate,
  faCaretUp,
  faCaretDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function Waivers(props) {
  const { seasonId, gameweek, teamId } = props;

  const [playersForTeam, setPlayersForTeam] = useState([]);
  const [selectedPlayersFromTeam, setSelectedPlayersFromTeam] = useState([]);
  const [waiverList, setWaiverList] = useState([]);
  const [savedWaiverList, setSavedWaiverList] = useState([]);
  const [waiversDate, setWaiversDate] = useState("");
  const [waiversMutation, setWaiversMutation] = useState([]);

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { parseTimestamp, actionAllowedBasedOnDate } = useDates();
  const { globalWaiversDeadline } = useContext(GlobalContext);

  let loading;

  const selectPlayerToDropMode = "selectPlayerToDropMode";
  const selectPlayersToPickUpMode = "selectPlayersToPickUpMode";

  const [currentSelectMode, setCurrentSelectMode] = useState(
    selectPlayerToDropMode
  );

  const FETCH_TEAM = gql`
    query ($teamId: String!) {
      getTeam(teamId: $teamId) {
        _id
        SeasonId
        Name
        ManagerId
        ManagerName
        RecentPoints
        TotalPoints
        Players {
          Current
          RecentPointsScoredForTeam
          TotalPointsScoredForTeam
          Player {
            _id
            WebName
            RecentPoints
            Fixtures
            Position
            News
            TotalPoints
            ChanceOfPlayingPercentage
            TeamName
          }
        }
      }
    }
  `;

  const FETCH_WAIVERS = gql`
    query ($seasonId: String!, $gameweek: Int!, $teamId: String!) {
      getWaivers(seasonId: $seasonId, gameweek: $gameweek, teamId: $teamId) {
        _id
        DateWaiversSet
        WaiverList {
          Position
          PlayerToDrop {
            _id
            WebName
            Fixtures
            Position
            News
            TotalPoints
            ChanceOfPlayingPercentage
            TeamName
          }
          PlayerToPickUp {
            _id
            WebName
            Fixtures
            Position
            News
            TotalPoints
            ChanceOfPlayingPercentage
            TeamName
          }
        }
      }
    }
  `;

  const SET_WAIVERS = gql`
    mutation setWaivers(
      $seasonId: String!
      $gameweek: Int!
      $teamId: String!
      $waiverList: [WaiverInput]!
    ) {
      setWaivers(
        seasonId: $seasonId
        gameweek: $gameweek
        teamId: $teamId
        waiverList: $waiverList
      ) {
        _id
        DateWaiversSet
        WaiverList {
          Position
          PlayerToDrop {
            _id
            WebName
            Fixtures
            Position
            News
            TotalPoints
            ChanceOfPlayingPercentage
            TeamName
          }
          PlayerToPickUp {
            _id
            WebName
            Fixtures
            Position
            News
            TotalPoints
            ChanceOfPlayingPercentage
            TeamName
          }
        }
      }
    }
  `;

  const { loadingTeam, data: { getTeam: returnedTeam } = {} } = useQuery(
    FETCH_TEAM,
    {
      variables: { teamId },
    }
  );

  const [
    loadWaiversData,
    { loadingWaivers, data: { getWaivers: returnedWaivers } = {} },
  ] = useLazyQuery(FETCH_WAIVERS, {
    variables: { seasonId, gameweek, teamId },
  });

  const [setWaivers, { loadingSetWaivers }] = useMutation(SET_WAIVERS, {
    update(_, { data: { setWaivers: waivers } }) {
      updateWaiversStateValues(waivers);
    },
    onError(err) {
      if (err.graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
        localStorage.removeItem("TLFPL_JWTTOKEN");
        navigate("/login");
        window.location.reload();
      }
    },
    variables: {
      seasonId: seasonId,
      gameweek: gameweek,
      teamId: teamId,
      waiverList: waiversMutation,
    },
  });

  useEffect(() => {
    loading = loadingTeam || loadingWaivers;
  }, [loadingTeam, loadingWaivers]);

  useEffect(() => {
    if (seasonId && (gameweek || gameweek === 0)) {
      loadWaiversData();
    }
  }, [seasonId, gameweek]);

  useEffect(() => {
    if (returnedTeam) {
      let players = returnedTeam.Players.filter((p) => p.Current);
      let playersStartingCount = players.length;

      if (playersStartingCount < 15) {
        for (let x = 0; x < 15 - playersStartingCount; x++) {
          let player = {
            Player: {
              _id: `blank_${x}`,
              Position: -1,
              WebName: "",
              Fixtures: "",
              TeamName: "",
              News: "",
              ChanceOfPlayingPercentage: 0,
              TotalPoints: 0,
            },
          };
          players.push(player);
        }
      }

      setPlayersForTeam([...players]);
    }
  }, [returnedTeam]);

  useEffect(() => {
    if (returnedWaivers && currentSelectMode !== selectPlayersToPickUpMode) {
      setSavedWaiverList([...returnedWaivers.WaiverList]);
      setWaiverList([...returnedWaivers.WaiverList]);
      setWaiversDate(returnedWaivers.DateWaiversSet);
    }
  }, [returnedWaivers]);

  useEffect(() => {
    if (waiverList) {
      updateWaiverMutationsOnWaiverListUpdate();
    }
  }, [waiverList]);

  const updateWaiversStateValues = (waivers) => {
    setSavedWaiverList([...waivers.WaiverList]);
    setSelectedPlayersFromTeam([]);
    setWaiversDate(waivers.DateWaiversSet);
    setCurrentSelectMode(selectPlayerToDropMode);
  };

  const setWaiversButtonVisible = () => {
    if (user && (user.teamId === teamId || user.role === "admin")) {
      if (
        (waiverList && waiverList.length > 0) ||
        (waiverList &&
          waiverList.length === 0 &&
          savedWaiverList &&
          savedWaiverList.length > 0)
      ) {
        return true;
      }
    }
  };

  const selectForWaiversButtonVisible = () => {
    if (user && (user.teamId === teamId || user.role === "admin")) {
      return true;
    }
  };

  const setWaiversButtonDisabled = () => {
    let allowedBasedOnDate = actionAllowedBasedOnDate(globalWaiversDeadline);
    let allowedBasedOnList = false;

    if (savedWaiverList && waiverList) {
      if (savedWaiverList.length !== waiverList.length) {
        allowedBasedOnList = true;
      } else {
        for (let x = 0; x < waiverList.length; x++) {
          let aDropId = waiverList[x].PlayerToDrop._id;
          let bDropId = savedWaiverList[x].PlayerToDrop._id;

          let aPickupId = waiverList[x].PlayerToPickUp._id;
          let bPickupId = savedWaiverList[x].PlayerToPickUp._id;

          if (aDropId !== bDropId || aPickupId !== bPickupId) {
            allowedBasedOnList = true;
            continue;
          }
        }
      }
    }

    if (!savedWaiverList && waiverList && waiverList.length > 0) {
      allowedBasedOnList = true;
    }

    if (allowedBasedOnList && allowedBasedOnDate) {
      return false;
    }

    return true;
  };

  const selectForWaiversButtonDisabled = () => {
    return selectedPlayersFromTeam.length !== 1;
  };

  const handleSelectForWaiversButtonClick = () => {
    setCurrentSelectMode(selectPlayersToPickUpMode);
  };

  const updateWaiverMutationsOnWaiverListUpdate = () => {
    const newWaiversMutation = [];

    waiverList.forEach((waiver, index) => {
      var { __typename: _, ...newPlayerToDrop } = waiver.PlayerToDrop;
      var { __typename: _, ...newPlayerToPickUp } = waiver.PlayerToPickUp;

      let newWaiver = {
        Position: index + 1,
        PlayerToDrop: newPlayerToDrop,
        PlayerToPickUp: newPlayerToPickUp,
      };

      newWaiversMutation.push(newWaiver);
    });

    setWaiversMutation(newWaiversMutation);
  };

  const handleSetWaiversButtonClick = () => {
    setWaivers();
  };

  const showWaiverUpButton = (index) => {
    if (index === 0) {
      return false;
    }

    return setWaiversButtonVisible();
  };

  const showWaiverDownButton = (index, count) => {
    if (index === count - 1) {
      return false;
    }

    return setWaiversButtonVisible();
  };

  const showWaiverRemoveButton = () => {
    return setWaiversButtonVisible();
  };

  const handleWaiverDownIconClick = (index) => {
    moveWaiver(waiverList, index, index + 1);
    setWaiverList([...waiverList]);
  };

  const handleWaiverUpIconClick = (index) => {
    moveWaiver(waiverList, index, index - 1);
    setWaiverList([...waiverList]);
  };

  const handleWaiverRemoveIconClick = (index) => {
    waiverList.splice(index, 1);
    setWaiverList([...waiverList]);
  };

  const moveWaiver = (array, from, to) => {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
      array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
  };

  const addToWaiverList = (player) => {
    for (let x = 0; x < waiverList.length; x++) {
      let aDropId = waiverList[x].PlayerToDrop._id;
      let bDropId = selectedPlayersFromTeam[0]._id;

      let aPickupId = waiverList[x].PlayerToPickUp._id;
      let bPickupId = player._id;

      if (aDropId === bDropId && aPickupId === bPickupId) {
        return;
      }
    }

    if (player.CurrentTeamName && player.CurrentTeamName !== "") {
      return;
    }

    const newWaiver = {
      Position: waiverList.length + 1,
      PlayerToDrop: playerCreator(selectedPlayersFromTeam[0]),
      PlayerToPickUp: playerCreator(player),
    };

    setWaiverList([...waiverList, newWaiver]);
  };

  const playerCreator = (player) => {
    return {
      _id: player._id,
      WebName: player.WebName,
      TotalPoints: player.TotalPoints,
      Fixtures: player.Fixtures,
      ChanceOfPlayingPercentage: player.ChanceOfPlayingPercentage,
      News: player.News,
      Position: player.Position,
      TeamName: player.TeamName,
    };
  };

  if (loading) {
    return <div>Loading waivers...</div>;
  }

  return (
    <React.Fragment>
      <div className="team-lineup">
        {waiversDate
          ? `Waivers @ ${parseTimestamp(waiversDate)}`
          : "Waivers not set"}

        {setWaiversButtonVisible() ? (
          <button
            disabled={setWaiversButtonDisabled()}
            onClick={(e) => {
              e.stopPropagation();
              handleSetWaiversButtonClick();
            }}
          >
            <FontAwesomeIcon icon={faRotate} size="lg" />
            Set
          </button>
        ) : (
          <span></span>
        )}
      </div>

      {loadingWaivers ? (
        <div>Loading waivers...</div>
      ) : (
        waiverList &&
        waiverList.length > 0 && (
          <div className="team-lineup">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Out</th>
                  <th></th>
                  <th>In</th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {waiverList.slice().map((w, index) => {
                  return (
                    <tr key={`waiver-${index}`}>
                      <td>{index + 1}</td>
                      <td key={w._id}>
                        <PlayerPosition
                          positionNumber={w.PlayerToDrop.Position}
                        />
                      </td>
                      <td>{w.PlayerToDrop.WebName}</td>
                      <td key={w._id}>
                        <PlayerPosition
                          positionNumber={w.PlayerToPickUp.Position}
                        />
                      </td>
                      <td>{w.PlayerToPickUp.WebName}</td>
                      <td>
                        {showWaiverUpButton(index) && (
                          <FontAwesomeIcon
                            icon={faCaretUp}
                            size="lg"
                            className="waiver-up-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleWaiverUpIconClick(index);
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {showWaiverDownButton(index, waiverList.length) && (
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            size="lg"
                            className="waiver-down-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleWaiverDownIconClick(index);
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {showWaiverRemoveButton() && (
                          <FontAwesomeIcon
                            icon={faXmark}
                            size="lg"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleWaiverRemoveIconClick(index);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )
      )}

      {returnedTeam &&
        !loadingWaivers &&
        currentSelectMode === selectPlayerToDropMode && (
          <React.Fragment>
            {selectForWaiversButtonVisible() && (
              <div className="team-lineup">
                <button
                  disabled={selectForWaiversButtonDisabled()}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectForWaiversButtonClick();
                  }}
                >
                  <FontAwesomeIcon icon={faRotate} size="lg" />
                  Select
                </button>
              </div>
            )}

            <PlayerList
              players={playersForTeam}
              selectedPlayers={selectedPlayersFromTeam}
              current="true"
              setSelectedPlayers={setSelectedPlayersFromTeam}
              canEdit={
                user && (user.teamId === teamId || user.role === "admin")
              }
              singleSelect="true"
            />
          </React.Fragment>
        )}

      {returnedTeam &&
        !loadingWaivers &&
        currentSelectMode === selectPlayersToPickUpMode && (
          <PlayerSearch
            seasonId={seasonId}
            gameweek={gameweek}
            waivers={true}
            selectPlayerToAddToWaivers={addToWaiverList}
          />
        )}
    </React.Fragment>
  );
}

export default Waivers;
