const dev = {
  url: {
    API: "http://localhost:5000/api",
  },
};

const prod = {
  url: {
    API: "https://theinsignificantleague.co.uk/api",
  },
};

export const environmentVars =
  process.env.NODE_ENV === "development" ? dev : prod;
