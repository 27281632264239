import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

function GameweekNav(props) {
  const { globalGameweek, currentlySelectedGameweek, prefix, future } = props;
  const navigate = useNavigate();

  const gameweek = currentlySelectedGameweek ?? globalGameweek;

  const validPreviousGameweek = () => {
    if (gameweek - 1 > 0) {
      return true;
    }

    return false;
  };

  const validNextGameweek = () => {
    if (gameweek + 1 <= globalGameweek + (future ? future : 0)) {
      return true;
    }

    return false;
  };

  const handlePreviousGameweekButtonClick = () => {
    navigate(`/${prefix}/${gameweek - 1}`);
  };

  const handleNextGameweekButtonClick = () => {
    navigate(`/${prefix}/${gameweek + 1}`);
  };

  return (
    <div className="gameweek-nav">
      <div className="gameweek-nav-buttons">
        <button
          className="nav-button"
          disabled={!validPreviousGameweek()}
          onClick={() => handlePreviousGameweekButtonClick()}
        >
          <FontAwesomeIcon icon={faCaretLeft} size="lg" />
        </button>
        <button
          className="nav-button"
          disabled={!validNextGameweek()}
          onClick={() => handleNextGameweekButtonClick()}
        >
          <FontAwesomeIcon icon={faCaretRight} size="lg" />
        </button>
      </div>
    </div>
  );
}

export default GameweekNav;
