import React from "react";

function PlayerPosition(props) {
  const { positionNumber } = props;

  const wc = <span className="position-player__blank">BLANK</span>;
  const gk = <span className="position-player__gk">GK</span>;
  const def = <span className="position-player__def">DEF</span>;
  const mid = <span className="position-player__mid">MID</span>;
  const forward = <span className="position-player__for">FOR</span>;

  const mapPositionNumber = (posNumber) => {
    if (posNumber === -1) {
      return wc;
    }

    if (posNumber === 1) {
      return gk;
    }

    if (posNumber === 2) {
      return def;
    }

    if (posNumber === 3) {
      return mid;
    }

    return forward;
  };

  return mapPositionNumber(positionNumber);
}

export default PlayerPosition;
