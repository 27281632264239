import React, { useContext } from "react";
import LoginForm from "../components/LoginForm";
import PageHeader from "../components/PageHeader";

import { GlobalContext } from "../contexts/GlobalContext";

function Login() {
  const { globalSeasonId } = useContext(GlobalContext);

  return (
    <div>
      <PageHeader pageTitle="Login" />
      <LoginForm seasonId={globalSeasonId} />
    </div>
  );
}

export default Login;
