import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

function Logout(props) {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogoutLinkClick = (event) => {
    event.preventDefault();
    context.logout();
    navigate("/");
  };

  return (
    <button onClick={handleLogoutLinkClick}>
      <FontAwesomeIcon icon={faRightFromBracket} size="lg" />
      Logout
    </button>
  );
}

export default Logout;
