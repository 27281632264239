import React, { useContext, useEffect } from "react";
import Waivers from "../components/Waivers";
import { useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import { GlobalContext } from "../contexts/GlobalContext";
import { useGlobalOrSelected } from "../hooks/useGlobalOrSelected";
import GameweekNav from "../components/GameweekNav";

import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function WaiversPage() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { globalSeasonId, globalGameweek } = useContext(GlobalContext);
  const { teamId, gameweek } = useParams();
  const { getGameweek } = useGlobalOrSelected();

  const gameweekNumber = getGameweek(globalGameweek, gameweek);

  useEffect(() => {
    if (!user || (user && user.teamId !== teamId && user.role !== "admin")) {
      return navigate("/");
    }
  }, [user]);

  const handleWaiversResultsGumpfClick = () => (e) => {
    navigate(`/waivers/${globalGameweek}`);
  };

  return (
    <React.Fragment>
      <div>
        <PageHeader pageTitle="Waivers" gameweek={gameweekNumber} />
        <Waivers
          seasonId={globalSeasonId}
          gameweek={globalGameweek}
          teamId={teamId}
        />
        {/* <GameweekNav
        globalGameweek={globalGameweek}
        currentlySelectedGameweek={gameweekNumber}
        prefix={`team/waivers/${teamId}`}
        future={1}
      /> */}
      </div>

      <div>
        <p onClick={handleWaiversResultsGumpfClick()}>
          Click here to view previous waiver results
        </p>
      </div>
    </React.Fragment>
  );
}

export default WaiversPage;
