import React, { createContext, useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import { AuthContext } from "./AuthContext";

export const GlobalContext = createContext();

const FETCH_GLOBALS = gql`
  query ($teamId: String!) {
    getGlobals(teamId: $teamId) {
      SeasonId
      Gameweek
      ActiveCup
      LineupSet
      LineupDeadline
      WaiversDeadline
    }
  }
`;

const GlobalProvider = (props) => {
  const { user } = useContext(AuthContext);
  const teamId = user ? user.teamId : "";

  const [globalSeasonId, setSeasonId] = useState("");
  const [globalGameweek, setGameweek] = useState(0);
  const [globalActiveCup, setActiveCup] = useState("");
  const [globalLineupDeadline, setLineupDeadline] = useState("");
  const [globalWaiversDeadline, setWaiversDeadline] = useState("");
  const [lineupSet, setLineupStatus] = useState(false);

  const { loading, data: { getGlobals: globals } = {} } = useQuery(
    FETCH_GLOBALS,
    { variables: { teamId } }
  );

  useEffect(() => {
    if (globals) {
      setSeasonId(globals.SeasonId);
      setGameweek(globals.Gameweek);
      setActiveCup(globals.ActiveCup);
      setLineupStatus(globals.LineupSet);
      setLineupDeadline(globals.LineupDeadline);
      setWaiversDeadline(globals.WaiversDeadline);
    }
  }, [loading, globals]);

  return (
    <GlobalContext.Provider
      value={{
        globalSeasonId,
        globalGameweek,
        globalActiveCup,
        lineupSet,
        setLineupStatus,
        globalLineupDeadline,
        globalWaiversDeadline,
      }}
    >
      {loading ? <div>Loading, please wait</div> : props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
