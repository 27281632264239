import React, { useContext } from "react";

import { GlobalContext } from "../contexts/GlobalContext";

import PageHeader from "../components/PageHeader";
import Table from "../components/Table";
import { useParams } from "react-router-dom";
import GameweekNav from "../components/GameweekNav";
import { useGlobalOrSelected } from "../hooks/useGlobalOrSelected";

function TablePage() {
  const { globalSeasonId, globalGameweek, globalActiveCup } =
    useContext(GlobalContext);
  const { gameweek, seasonId } = useParams();
  const { getGameweek, getSeasonId } = useGlobalOrSelected();

  const gameweekNumber = getGameweek(globalGameweek, gameweek);
  const seasonIdString = getSeasonId(globalSeasonId, seasonId);

  let prefix = "table";
  if (seasonId && globalSeasonId !== seasonId) {
    prefix = "records/" + seasonIdString;
  }

  let weeksInFuture = 0;
  if (prefix !== "table") {
    weeksInFuture = gameweekNumber - globalGameweek;

    let max = 38;
    if (seasonId === "3Pu8Cj8yLQxguchZP") {
      max = 47;
    }

    if (globalGameweek + weeksInFuture < max) {
      weeksInFuture = weeksInFuture + 1;
    }
  }

  return (
    <div>
      <PageHeader pageTitle="League Table" gameweek={gameweekNumber} />
      <Table
        seasonId={seasonIdString}
        gameweek={gameweekNumber}
        globalSeasonId={globalSeasonId}
        globalActiveCup={globalActiveCup}
      />
      <GameweekNav
        globalGameweek={globalGameweek}
        currentlySelectedGameweek={gameweekNumber}
        prefix={prefix}
        future={weeksInFuture}
      />
    </div>
  );
}

export default TablePage;
