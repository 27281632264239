import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";

function PageHeader(props) {
  const { pageTitle, gameweek } = props;
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleTeamButtonClick = (teamId) => {
    navigate("/team/" + teamId);
  };

  return (
    <div className="page-header">
      <h1>
        {pageTitle}
        {gameweek ? (
          <span className="page-header-gameweek">GW{gameweek}</span>
        ) : (
          <span></span>
        )}
      </h1>
      {user && (
        <button
          className="page-header-team-button nav-button"
          onClick={() => handleTeamButtonClick(user.teamId)}
        >
          <FontAwesomeIcon icon={faPeopleGroup} size="lg" />
        </button>
      )}
    </div>
  );
}

export default PageHeader;
