import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import { useDates } from "../hooks/useDates";

function Gameweeks(props) {
  const { seasonId, gameweek } = props;
  const navigate = useNavigate();
  const { parseTimestamp } = useDates();

  const FETCH_GAMEWEEKS = gql`
    query ($seasonId: String!, $gameweek: Int!) {
      getGameweeks(seasonId: $seasonId, gameweek: $gameweek) {
        TeamId
        TeamName
        Formation
        DateLineupSet
      }
    }
  `;

  const [
    loadGameweeksData,
    { loading, data: { getGameweeks: returnedGameweeks } = {} },
  ] = useLazyQuery(FETCH_GAMEWEEKS, {
    variables: { seasonId, gameweek },
  });

  useEffect(() => {
    if (seasonId && gameweek) {
      loadGameweeksData();
    }
  }, [seasonId, gameweek]);

  const lineupSet = (timestampString) => {
    if (timestampString.indexOf("-") > -1) {
      return false;
    }

    return true;
  };

  const parseDate = (timestampString) => {
    if (!lineupSet(timestampString)) {
      return " - ";
    }

    return parseTimestamp(timestampString);
  };

  const handleRowClick = (teamId, timestampString) => (e) => {
    if (lineupSet(timestampString)) {
      navigate("/lineup/" + teamId);
    }
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Team</th>
          <th>Lineup Date</th>
          <th>Formation</th>
        </tr>
      </thead>

      <tbody>
        {loading ? (
          <tr>
            <td>Loading gameweeks...</td>
          </tr>
        ) : (
          returnedGameweeks &&
          returnedGameweeks
            .slice()
            .sort((a, b) => a.DateLineupSet - b.DateLineupSet)
            .map((g, index) => {
              return (
                <tr
                  onClick={handleRowClick(g.TeamId, g.DateLineupSet)}
                  key={index}
                >
                  <td>{g.TeamName}</td>
                  <td>{parseDate(g.DateLineupSet)}</td>
                  <td>{g.Formation}</td>
                </tr>
              );
            })
        )}
      </tbody>
    </table>
  );
}

export default Gameweeks;
