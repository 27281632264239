import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

import PlayerPosition from "./PlayerPosition";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faPlaneDeparture,
  faBan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function PlayerSearch(props) {
  const { seasonId, waivers, selectPlayerToAddToWaivers } = props;
  const [activeNewsItem, setActiveNewsItem] = useState("");

  const [isAvailableChecked, setIsAvailableChecked] = useState(
    waivers ? true : false
  );
  const [selectedPosition, setSelectedPosition] = useState(0);
  const [isRuledOutChecked, setIsRuledOutChecked] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState("");

  const FETCH_PLAYERS = gql`
    query (
      $seasonId: String!
      $available: Boolean
      $position: Int
      $team: String
      $excludeRuledOut: Boolean
    ) {
      getPlayers(
        seasonId: $seasonId
        available: $available
        position: $position
        team: $team
        excludeRuledOut: $excludeRuledOut
      ) {
        _id
        WebName
        Fixtures
        Position
        News
        TotalPoints
        ChanceOfPlayingPercentage
        CurrentTeamName
        TeamName
      }
    }
  `;

  const [loadPlayerData, { loading, data: { getPlayers: players } = {} }] =
    useLazyQuery(FETCH_PLAYERS, {
      variables: {
        seasonId,
        available: isAvailableChecked,
        position: selectedPosition,
        team: selectedTeam,
        excludeRuledOut: !isRuledOutChecked,
      },
    });

  useEffect(() => {
    if (seasonId) {
      loadPlayerData();
    }
  }, [
    seasonId,
    isAvailableChecked,
    selectedPosition,
    selectedTeam,
    isRuledOutChecked,
  ]);

  const playerReasonForAbsence = (news) => {
    if (!news) {
      return "";
    }

    let newsLower = news.toLowerCase();

    if (newsLower.includes("loan") || newsLower.includes("transfer")) {
      return faPlaneDeparture;
    }

    if (newsLower.includes("suspended")) {
      return faBan;
    }

    return faTriangleExclamation;
  };

  const playerChanceOfPlayingClass = (chanceOfPlayingPercentage) => {
    if (chanceOfPlayingPercentage < 0) {
      return "";
    }

    return `player-news-chance-${chanceOfPlayingPercentage}-icon`;
  };

  const handlePlayerNewsIconClick = (playerId) => {
    if (activeNewsItem === playerId + "_news") {
      setActiveNewsItem("");
      return;
    }

    setActiveNewsItem(playerId + "_news");
  };

  const handlePlayerNewsRowClick = () => {
    setActiveNewsItem("");
  };

  const handleAvailableClick = (event) => {
    setIsAvailableChecked(event.target.checked);
  };

  const handleSelectedPosition = (event) => {
    setSelectedPosition(parseInt(event.target.value));
  };

  const handleSelectedTeam = (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleRuledOutClick = (event) => {
    setIsRuledOutChecked(event.target.checked);
  };

  const handlePlayerClick = (player) => {
    selectPlayerToAddToWaivers(player);
  };

  return (
    <React.Fragment>
      <form id="player-search">
        <label>
          Available
          <input
            type="checkbox"
            checked={isAvailableChecked}
            onChange={handleAvailableClick}
          />
        </label>
        <label>
          Pos
          <select value={selectedPosition} onChange={handleSelectedPosition}>
            <option value="0">All</option>
            <option value="1">GK</option>
            <option value="2">DEF</option>
            <option value="3">MID</option>
            <option value="4">FOR</option>
          </select>
        </label>
        <label>
          Team
          <select value={selectedTeam} onChange={handleSelectedTeam}>
            <option value="">Any</option>
            <option value="ARS">ARS</option>
            <option value="AVL">AVL</option>
            <option value="BHA">BHA</option>
            <option value="BOU">BOU</option>
            <option value="BRE">BRE</option>
            <option value="CHE">CHE</option>
            <option value="CRY">CRY</option>
            <option value="EVE">EVE</option>
            <option value="FUL">FUL</option>
            <option value="IPS">IPS</option>
            <option value="LEI">LEI</option>
            <option value="LIV">LIV</option>
            <option value="MCI">MCI</option>
            <option value="MUN">MUN</option>
            <option value="NEW">NEW</option>
            <option value="NFO">NFO</option>
            <option value="SOU">SOU</option>
            <option value="TOT">TOT</option>
            <option value="WHU">WHU</option>
            <option value="WOL">WOL</option>
          </select>
        </label>
        <label>
          Ruled Out
          <input
            type="checkbox"
            checked={isRuledOutChecked}
            onChange={handleRuledOutClick}
          />
        </label>
      </form>

      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Name</th>
            <th>Fixtures</th>
            <th>TP</th>
            <th>Team</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td>Loading players...</td>
            </tr>
          ) : (
            players &&
            players.map((p) => (
              <React.Fragment key={p._id}>
                <tr onClick={() => handlePlayerClick(p)}>
                  <td>
                    <PlayerPosition positionNumber={p.Position} />
                  </td>
                  <td>{p.WebName}</td>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: p.Fixtures,
                      }}
                    />
                  </td>
                  <td>{p.TotalPoints}</td>
                  <td>{p.CurrentTeamName}</td>
                  <td className="player-news-cell">
                    {p.News && (
                      <FontAwesomeIcon
                        icon={playerReasonForAbsence(p.News)}
                        size="lg"
                        className={playerChanceOfPlayingClass(
                          p.ChanceOfPlayingPercentage
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePlayerNewsIconClick(p._id);
                        }}
                      />
                    )}
                  </td>
                </tr>
                {p.News !== "" && (
                  <tr hidden={activeNewsItem !== p._id + "_news"}>
                    <td
                      colSpan="5"
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePlayerNewsRowClick();
                      }}
                    >
                      {p.News}
                    </td>
                    <td className="player-news-cell">
                      <FontAwesomeIcon
                        icon={faXmark}
                        size="lg"
                        className="player-news-warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePlayerNewsRowClick();
                        }}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default PlayerSearch;
