export const useGlobalOrSelected = () => {
  const getGameweek = (globalGameweek, selectedGameweek) => {
    if (selectedGameweek) {
      return Number(selectedGameweek);
    }

    return globalGameweek;
  };

  const getSeasonId = (globalSeasonId, selectedSeasonId) => {
    if (selectedSeasonId) {
      return selectedSeasonId;
    }

    return globalSeasonId;
  };

  return {
    getGameweek,
    getSeasonId,
  };
};
