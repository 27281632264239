import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import { GlobalContext } from "../contexts/GlobalContext";
import Team from "../components/Team";

function TeamPage() {
  const { globalSeasonId, globalGameweek } = useContext(GlobalContext);
  const { teamId } = useParams();

  const [teamName, setTeamName] = useState("");

  const getTeamNameFromSquad = (nameFromSquad) => {
    setTeamName(nameFromSquad);
  };

  return (
    <div>
      <PageHeader pageTitle={teamName} />
      <Team
        seasonId={globalSeasonId}
        gameweek={globalGameweek + 1}
        teamId={teamId}
        teamNameCallback={getTeamNameFromSquad}
      />
    </div>
  );
}

export default TeamPage;
