import React, { useContext } from "react";
import PageHeader from "../components/PageHeader";
import { GlobalContext } from "../contexts/GlobalContext";
import GameweekNav from "../components/GameweekNav";
import { useGlobalOrSelected } from "../hooks/useGlobalOrSelected";
import { useParams } from "react-router-dom";
import WaiversRecord from "../components/WaiversRecord";

function WaiversRecords() {
  const { globalSeasonId, globalGameweek } = useContext(GlobalContext);
  const { gameweek } = useParams();
  const { getGameweek } = useGlobalOrSelected();
  const gameweekNumber = getGameweek(globalGameweek, gameweek);

  return (
    <div>
      <PageHeader pageTitle="Waivers Records" gameweek={gameweekNumber} />
      <WaiversRecord seasonId={globalSeasonId} gameweek={gameweekNumber} />
      <GameweekNav
        globalGameweek={globalGameweek}
        currentlySelectedGameweek={gameweekNumber}
        prefix="waivers"
      />
    </div>
  );
}

export default WaiversRecords;
