import React from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../SubHeader";
import Trophies from "./Trophies";

function CupResults(props) {
  const { cupResults } = props;
  const navigate = useNavigate();

  const handleManagerClick = (managerId) => {
    navigate("/manager/" + managerId);
  };

  return (
    <div>
      {cupResults && (
        <React.Fragment>
          <SubHeader subTitle="Cup Results" />

          <table>
            <thead>
              <tr>
                <th>Pos</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cupResults
                .slice()
                .sort((a, b) => b.CupSorting - a.CupSorting)
                .map((lr, index) => {
                  return (
                    <tr
                      key={lr.ManagerId}
                      onClick={() => handleManagerClick(lr.ManagerId)}
                    >
                      <td>{index + 1}</td>
                      <td>{lr.ManagerName}</td>
                      <td>
                        <Trophies
                          winnerCount={lr.CupWinner}
                          runnerUpCount={lr.CupRunnerUp}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </React.Fragment>
      )}
    </div>
  );
}

export default CupResults;
