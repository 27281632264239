import React from "react";

function SubHeader(props) {
  const { subTitle, gameweek } = props;

  return (
    <h2 className="sub-header">
      {subTitle}
      {gameweek ? (
        <span className="sub-header-gameweek">GW{gameweek}</span>
      ) : (
        <span></span>
      )}
    </h2>
  );
}

export default SubHeader;
