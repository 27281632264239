import React from "react";
import { useNavigate } from "react-router-dom";
import SubHeader from "../SubHeader";
import Trophies from "./Trophies";

function LeagueResults(props) {
  const { leagueResults } = props;
  const navigate = useNavigate();

  const handleManagerClick = (managerId) => {
    navigate("/manager/" + managerId);
  };

  return (
    <div>
      {leagueResults && (
        <React.Fragment>
          <SubHeader subTitle="League Results" />

          <table>
            <thead>
              <tr>
                <th>Pos</th>
                <th>Name</th>
                <th>Pts</th>
                <th>Score</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {leagueResults
                .slice()
                .sort(
                  (a, b) =>
                    b.HallOfFamePoints - a.HallOfFamePoints ||
                    b.TotalPoints - a.TotalPoints
                )
                .map((lr, index) => {
                  return (
                    <tr
                      key={lr.ManagerId}
                      onClick={() => handleManagerClick(lr.ManagerId)}
                    >
                      <td>{index + 1}</td>
                      <td>{lr.ManagerName}</td>
                      <td>{lr.TotalPoints}</td>
                      <td>{lr.HallOfFamePoints}</td>
                      <td>
                        <Trophies
                          winnerCount={lr.LeagueWinner}
                          runnerUpCount={lr.LeagueRunnerUp}
                          thirdCount={lr.LeagueThird}
                          loserCount={lr.LeagueLoser}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </React.Fragment>
      )}
    </div>
  );
}

export default LeagueResults;
