import React, { useContext } from "react";
import Gameweek from "../components/Gameweek";
import { useParams } from "react-router-dom";
import PageHeader from "../components/PageHeader";
import { GlobalContext } from "../contexts/GlobalContext";

function Lineup() {
  const { globalSeasonId, globalGameweek } = useContext(GlobalContext);
  const { teamId } = useParams();

  return (
    <div>
      <PageHeader pageTitle="Lineup" gameweek={globalGameweek + 1} />
      <Gameweek
        seasonId={globalSeasonId}
        gameweek={globalGameweek + 1}
        teamId={teamId}
      />
    </div>
  );
}

export default Lineup;
