import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import SubHeader from "../SubHeader";

function CupTables(props) {
  const { seasonId, gameweek, activeCup } = props;

  const FETCH_CUP_TABLES = gql`
    query ($seasonId: String!, $gameweek: Int!, $activeCup: Int!) {
      getCupTables(
        seasonId: $seasonId
        gameweek: $gameweek
        activeCup: $activeCup
      ) {
        CupGroupId
        CupGroupName
        Gameweek
        Standings {
          Position
          TeamName
          Win
          Lose
          Draw
          Points
          TotalPointsDifference
        }
      }
    }
  `;

  const [
    loadCupTableData,
    { loading, data: { getCupTables: cupTables } = {} },
  ] = useLazyQuery(FETCH_CUP_TABLES, {
    variables: { seasonId, gameweek, activeCup },
  });

  useEffect(() => {
    if (seasonId && gameweek && activeCup) {
      loadCupTableData();
    }
  }, [seasonId, gameweek, activeCup]);

  return (
    <div>
      {loading ? (
        <div>Loading cup tables...</div>
      ) : (
        cupTables &&
        cupTables.map((ct) => {
          return (
            <React.Fragment key={ct.CupGroupId}>
              <SubHeader subTitle={ct.CupGroupName} gameweek={ct.Gameweek} />
              <table>
                <thead>
                  <tr>
                    <th>Pos</th>
                    <th>Team</th>
                    <th>P</th>
                    <th>W</th>
                    <th>D</th>
                    <th>L</th>
                    <th>+/-</th>
                    <th>Points</th>
                  </tr>
                </thead>

                <tbody>
                  {ct.Standings.map((s) => {
                    return (
                      <React.Fragment key={s.Position}>
                        <tr>
                          <td>{s.Position}</td>
                          <td>{s.TeamName}</td>
                          <td>{s.Win + s.Draw + s.Lose}</td>
                          <td>{s.Win}</td>
                          <td>{s.Draw}</td>
                          <td>{s.Lose}</td>
                          <td>{s.TotalPointsDifference}</td>
                          <td>{s.Points}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </React.Fragment>
          );
        })
      )}
    </div>
  );
}

export default CupTables;
