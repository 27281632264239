import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useNavigate } from "react-router-dom";
import SubHeader from "./SubHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faCircleExclamation,
  faSkull,
} from "@fortawesome/free-solid-svg-icons";

function Table(props) {
  const { seasonId, gameweek, globalSeasonId, globalActiveCup } = props;
  const navigate = useNavigate();
  const [seasonName, setSeasonName] = useState("");

  const FETCH_TABLE = gql`
    query ($seasonId: String!, $gameweek: Int!) {
      getTable(seasonId: $seasonId, gameweek: $gameweek) {
        SeasonId
        SeasonName
        Standings {
          Position
          TeamId
          TeamName
          RecentPoints
          TotalPoints
          PreviousPosition
          PointsBehind
        }
      }
    }
  `;

  const FETCH_CUP_ELIMINATOR = gql`
    query ($seasonId: String!, $gameweek: Int!, $activeCup: Int!) {
      getCupEliminator(
        seasonId: $seasonId
        gameweek: $gameweek
        activeCup: $activeCup
      ) {
        AtRisk {
          TeamId
        }
        Rollover {
          TeamId
        }
        Eliminated {
          TeamId
        }
      }
    }
  `;

  const [loadTableData, { loading, data: { getTable: table } = {} }] =
    useLazyQuery(FETCH_TABLE, {
      variables: { seasonId, gameweek },
    });

  const [
    loadCupEliminator,
    { loadingCupEliminator, data: { getCupEliminator: cupEliminator } = {} },
  ] = useLazyQuery(FETCH_CUP_ELIMINATOR, {
    variables: { seasonId, gameweek, activeCup: globalActiveCup },
  });

  const handleRowClick = (teamId) => (e) => {
    if (globalSeasonId !== seasonId) {
      return;
    }

    if (gameweek === 0) {
      navigate(`/team/${teamId}`);
    } else {
      navigate(`/result/${teamId}/${gameweek}`);
    }
  };

  useEffect(() => {
    if (seasonId && (gameweek || gameweek === 0)) {
      loadTableData();
    }
  }, [seasonId, gameweek]);

  useEffect(() => {
    if (globalActiveCup && globalActiveCup > 0) {
      loadCupEliminator();
    }
  }, [globalActiveCup]);

  useEffect(() => {
    if (table && table.SeasonId !== globalSeasonId) {
      setSeasonName(table.SeasonName);
    }
  }, [table]);

  const showCupEliminatorIcon = (teamId) => {
    if (
      cupEliminator.AtRisk.some((ar) => ar.TeamId === teamId) ||
      cupEliminator.Rollover.some((ar) => ar.TeamId === teamId) ||
      cupEliminator.Eliminated.some((ar) => ar.TeamId === teamId)
    ) {
      return true;
    }

    return false;
  };

  const cupEliminatorIcon = (teamId) => {
    if (cupEliminator.Eliminated.some((ar) => ar.TeamId === teamId)) {
      return faSkull;
    }

    if (cupEliminator.Rollover.some((ar) => ar.TeamId === teamId)) {
      return faCircleExclamation;
    }

    if (cupEliminator.AtRisk.some((ar) => ar.TeamId === teamId)) {
      return faExclamation;
    }
  };

  return (
    <React.Fragment>
      {table ? (
        <div>
          <SubHeader subTitle={seasonName} />

          <table>
            <thead>
              <tr>
                <th>Pos</th>
                <th>Team</th>
                <th>Recent</th>
                <th>Total</th>
                <th>Behind</th>
              </tr>
            </thead>

            <tbody>
              {table &&
                table.Standings.map((s) => (
                  <React.Fragment key={s.Position}>
                    <tr onClick={handleRowClick(s.TeamId)}>
                      <td>{s.Position === 1 ? <span>1</span> : s.Position}</td>
                      <td>
                        {cupEliminator && showCupEliminatorIcon(s.TeamId) && (
                          <FontAwesomeIcon
                            icon={cupEliminatorIcon(s.TeamId)}
                            size="lg"
                            className="cup-eliminator-icon"
                          />
                        )}{" "}
                        <span> </span> {s.TeamName}
                      </td>
                      <td>{s.RecentPoints}</td>
                      <td>{s.TotalPoints}</td>
                      <td>{s.PointsBehind}</td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>
      ) : loading ? (
        <p>Loading...</p>
      ) : (
        <p>Could not find data for season</p>
      )}
    </React.Fragment>
  );
}

export default Table;
