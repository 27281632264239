import React, { useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import PlayerList from "./PlayerList";
import ManagerLink from "./ManagerLink";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useDates } from "../hooks/useDates";
import { GlobalContext } from "../contexts/GlobalContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faRotate } from "@fortawesome/free-solid-svg-icons";

function Team(props) {
  const { seasonId, gameweek, teamId, teamNameCallback } = props;

  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [initialSelection, setInitialSelection] = useState([]);
  const [formation, setFormation] = useState("");
  const [lineupDate, setLineupDate] = useState("");

  const { user } = useContext(AuthContext);
  const { setLineupStatus, globalLineupDeadline } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { parseTimestamp, actionAllowedBasedOnDate } = useDates();

  let loading;

  const FETCH_TEAM = gql`
    query ($teamId: String!) {
      getTeam(teamId: $teamId) {
        _id
        SeasonId
        Name
        ManagerId
        ManagerName
        RecentPoints
        TotalPoints
        Players {
          Current
          RecentPointsScoredForTeam
          TotalPointsScoredForTeam
          Player {
            _id
            WebName
            RecentPoints
            Fixtures
            Position
            News
            TotalPoints
            ChanceOfPlayingPercentage
          }
        }
      }
    }
  `;

  const FETCH_LINEUP = gql`
    query ($seasonId: String!, $gameweek: Int!, $teamId: String!) {
      getLineup(seasonId: $seasonId, gameweek: $gameweek, teamId: $teamId) {
        _id
        Players
        Formation
        DateSet
      }
    }
  `;

  const SET_LINEUP = gql`
    mutation setLineup(
      $seasonId: String!
      $gameweek: Int!
      $teamId: String!
      $players: [String]!
      $formation: String!
    ) {
      setLineup(
        seasonId: $seasonId
        gameweek: $gameweek
        teamId: $teamId
        players: $players
        formation: $formation
      ) {
        _id
        DateSet
        Players
        Formation
      }
    }
  `;

  const { loadingTeam, data: { getTeam: returnedTeam } = {} } = useQuery(
    FETCH_TEAM,
    {
      variables: { teamId },
    }
  );

  const [
    loadLineupData,
    { loadingLineup, data: { getLineup: returnedLineup } = {} },
  ] = useLazyQuery(FETCH_LINEUP, {
    variables: { seasonId, gameweek, teamId },
  });

  const [setLineup, { loadingSetLineup }] = useMutation(SET_LINEUP, {
    update(_, { data: { setLineup: lineup } }) {
      updateLineupStateValues(lineup);
      setLineupStatus(true);
    },
    onError(err) {
      if (err.graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
        localStorage.removeItem("TLFPL_JWTTOKEN");
        navigate("/login");
        window.location.reload();
      }
    },
    variables: {
      seasonId: seasonId,
      gameweek: gameweek,
      teamId: teamId,
      players: selectedPlayers,
      formation: formation,
    },
  });

  useEffect(() => {
    loading = loadingTeam || loadingLineup;
  }, [loadingTeam, loadingLineup]);

  useEffect(() => {
    if (returnedTeam) {
      teamNameCallback(returnedTeam.Name);
    }

    if (returnedLineup) {
      updateLineupStateValues(returnedLineup);
    }
  }, [returnedTeam, returnedLineup]);

  useEffect(() => {
    if (seasonId && gameweek) {
      loadLineupData();
    }
  }, [seasonId, gameweek]);

  const updateLineupStateValues = (lineup) => {
    setInitialSelection(lineup.Players);
    setFormation(lineup.Formation);
    setSelectedPlayers(lineup.Players);
    setLineupDate(lineup.DateSet);
  };

  const setLineupButtonVisible = () => {
    if (user && (user.teamId === teamId || user.role === "admin")) {
      return true;
    }
  };

  const waiversButtonVisible = () => {
    if (user && (user.teamId === teamId || user.role === "admin")) {
      return true;
    }
  };

  const setLineupButtonDisabled = () => {
    if (selectedPlayers.length !== 11) {
      return true;
    }

    if (initialSelection.length > 0) {
      const diff = initialSelection.filter((x) => !selectedPlayers.includes(x));
      if (diff.length === 0) {
        return true;
      }
    }

    let allowedBasedOnDate = actionAllowedBasedOnDate(globalLineupDeadline);
    if (!allowedBasedOnDate) {
      return true;
    }
  };

  const handleSetLineupButtonClick = () => {
    setLineup();
  };

  const handleWaiversButtonClick = () => {
    navigate("/team/waivers/" + teamId + "/" + gameweek);
  };

  if (loading) {
    return <div>Loading team...</div>;
  }

  return (
    <React.Fragment>
      {returnedTeam && (
        <ManagerLink
          managerName={returnedTeam.ManagerName}
          managerId={returnedTeam.ManagerId}
        />
      )}
      <div className="team-lineup">
        {waiversButtonVisible() ? (
          <button
            onClick={() => handleWaiversButtonClick()}
            className="nav-button"
          >
            <FontAwesomeIcon icon={faRotate} size="lg" />
            Waivers
          </button>
        ) : (
          <span></span>
        )}

        {lineupDate
          ? `Lineup @ ${parseTimestamp(lineupDate)}`
          : "Lineup not set"}
        {setLineupButtonVisible() ? (
          <button
            disabled={setLineupButtonDisabled()}
            onClick={() => handleSetLineupButtonClick()}
          >
            <FontAwesomeIcon icon={faCalendarDays} size="lg" />
            Set
          </button>
        ) : (
          <span></span>
        )}
      </div>
      {returnedTeam && !loadingLineup && (
        <PlayerList
          players={returnedTeam.Players.filter((p) => p.Current)}
          selectedPlayers={selectedPlayers}
          current="true"
          setSelectedPlayers={setSelectedPlayers}
          setFormation={setFormation}
          canEdit={user && (user.teamId === teamId || user.role === "admin")}
        />
      )}
    </React.Fragment>
  );
}

export default Team;
