import React, { useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import SubHeader from "../SubHeader";

function CupKnockoutFixtures(props) {
  const { seasonId, activeCup } = props;

  const FETCH_CUP_KNOCKOUT_FIXTURES = gql`
    query ($seasonId: String!, $activeCup: Int!) {
      getCupKnockoutFixtures(seasonId: $seasonId, activeCup: $activeCup) {
        _id
        Round
        RoundOrder
        Fixtures {
          Leg
          Week
          Matches {
            MatchNumber
            FixtureName
            Team1 {
              TeamName
              Points
            }
            Team2 {
              TeamName
              Points
            }
          }
        }
      }
    }
  `;

  const [
    loadCupKnockoutFixturesData,
    { loading, data: { getCupKnockoutFixtures: cupKnockoutFixtures } = {} },
  ] = useLazyQuery(FETCH_CUP_KNOCKOUT_FIXTURES, {
    variables: { seasonId, activeCup },
  });

  useEffect(() => {
    if (seasonId && activeCup) {
      loadCupKnockoutFixturesData();
    }
  }, [seasonId, activeCup]);

  return (
    <div>
      {loading ? (
        <div>Loading cup tables...</div>
      ) : (
        cupKnockoutFixtures &&
        cupKnockoutFixtures
          .slice()
          .sort((a, b) => b.RoundOrder - a.RoundOrder)
          .map((ckf) => {
            return (
              <React.Fragment key={ckf._id}>
                <SubHeader subTitle={ckf.Round} />
                <table>
                  <thead>
                    <tr>
                      <th>Leg</th>
                      <th>GW</th>
                      <th>Fixtures</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ckf.Fixtures.map((fixture) => {
                      return (
                        <tr
                          className="cup-knockout-fixtures-row"
                          key={fixture.Leg}
                        >
                          <td>{fixture.Leg}</td>
                          <td>{fixture.Week}</td>
                          <td>
                            <table className="cup-knockout-fixtures">
                              <tbody>
                                {fixture.Matches.map((match) => {
                                  return (
                                    <tr key={match.MatchNumber}>
                                      <td width="35%">
                                        {match.Team1.TeamName}
                                      </td>
                                      <td width="10%">{match.Team1.Points}</td>
                                      <td width="10%">vs</td>
                                      <td width="10%">{match.Team2.Points}</td>
                                      <td width="35%">
                                        {match.Team2.TeamName}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </React.Fragment>
            );
          })
      )}
    </div>
  );
}

export default CupKnockoutFixtures;
