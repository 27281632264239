import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

import LeagueResults from "./LeagueResults";
import CupResults from "./CupResults";

const FETCH_HALLOFFAME = gql`
  {
    getHallOfFame {
      LeagueResults {
        ManagerId
        ManagerName
        TotalPoints
        HallOfFamePoints
        LeagueWinner
        LeagueRunnerUp
        LeagueThird
        LeagueLoser
      }
      CupResults {
        ManagerId
        ManagerName
        CupWinner
        CupRunnerUp
        CupSorting
      }
    }
  }
`;

function LeagueAndCup(props) {
  const { loading, data: { getHallOfFame: hallOfFame } = {} } =
    useQuery(FETCH_HALLOFFAME);

  return (
    <div>
      {loading ? (
        <div>Loading records...</div>
      ) : (
        <React.Fragment>
          <LeagueResults leagueResults={hallOfFame.LeagueResults} />
          <CupResults cupResults={hallOfFame.CupResults} />
        </React.Fragment>
      )}
    </div>
  );
}

export default LeagueAndCup;
