export const useDates = () => {
  const parseTimestamp = (timestampString) => {
    const date = new Date(parseInt(timestampString));
    return date.toLocaleString("en-GB");
  };

  const actionAllowedBasedOnDate = (deadlineDateString) => {
    const deadlineDate = new Date(parseInt(deadlineDateString));
    const nowDate = new Date();

    if (nowDate <= deadlineDate) {
      return true;
    }

    return false;
  };

  return {
    parseTimestamp,
    actionAllowedBasedOnDate,
  };
};
